<template>
    <div class="invoices-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="invoices-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input id="search" name="search" label="Search invoices" type="text" placeholder="Enter keywords"
                        :debounce="500" v-model:value="filters.q">
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-auto">
                        <date-range-picker ref="picker" v-model="range" opens="right" time-picker time-picker-24-hours
                            show-dropdowns control-container-class="invoices-list__date-range-picker"
                            :linked-calendars="false" @update="changeDate">
                            <template #input>
                                <em class="fa fa-fw fa-calendar ml-n1"></em>
                                {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                                {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                                <strong class="caret"></strong>
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple id="status" name="status" class="w-100" option-label="key"
                            option-value="value" label="Status" :options="_available_filters.status"
                            v-model:value="filters.status" />
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple id="months" name="months" class="w-100" option-label="key"
                            option-value="value" label="Months" :options="_available_filters.months"
                            v-model:value="filters.month" />
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple id="years" name="years" class="w-100" option-label="value"
                            option-value="value" label="Years" :options="_available_filters.years"
                            v-model:value="filters.year" />
                    </div>
                </template>
            </div>
            <ui-table class="mb-4" :items="invoices" :fields="fields" :busy="busy" :scopes="['write_invoices', 'read_invoices']" >
                <template #cell(name)="data">
                    <span v-if="!data.item.account || Object.keys(data.item.account).length == 0" class="badge bg-light text-dark">
                        No information
                    </span>
                    <span v-else >
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600"> {{ data.item.account.first_name }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.account.email" underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                            <ui-link v-else-if="data.item.account.phone" underline weight="regular" :link="`tel:+${data.item.account.phone}`">
                                {{ data.item.account.phone }}
                            </ui-link>
                        </div>
                        <ui-badge v-if="data.item.account.name" variant="success" class="align-self-start badge-large">{{ data.item.account.name }}</ui-badge>
                    </span>
                </template>
                <template #cell(amount)="data">
                    {{ _currency(data.item.amount, data.item.currency, true) }}
                </template>
                <template #cell(concepts)="data">
                    <span>{{ data.item.concepts.length }}</span>
                </template>
                <template #cell(month)="data">
                    <span>{{ data.item.month }}</span>
                </template>
                <template #cell(year)="data">
                    <span>{{ data.item.year }}</span>
                </template>
                <template #cell(status)="data">
                    <ui-status class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        :status="data.item.status" />
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(updated_at)="data">
                    {{ $dayjs(data.item.updated_at).format('LL') }} <br />
                    {{ $dayjs(data.item.updated_at).format('LTS') }}
                </template>
                <template #cell(actions)="data">
                    <div v-if="CAN(['read_transactions', 'write_transactions', 'write_invoices'])">
                        <ui-button-group>
                            <ui-button variant="outline-primary" icon="fas fa-download"
                                :disabled="data.item.status === 'pending'" :loading="downloadingInvoice && selectedID === data.item.id"
                                @click="downloadInvoice(data.item.id)">
                                Download
                            </ui-button>
                            <ui-dropdown ref="dropdown" variant="outline-primary">
                                <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                </template>
                                <ui-dropdown-item v-if="CAN(['read_transactions', 'write_transactions'])" :to="{ name: 'transactions', query: { invoice_id: data.item.id } }"
                                    target="_blank">
                                    See details
                                </ui-dropdown-item>
                                <ui-dropdown-item v-if="data.item.status === 'pending' && CAN(['write_invoices'])" :disabled="sendingToBill"
                                    @click.stop="sendToBill(data.item.id)">
                                    <span v-if="sendingToBill" class="fas fa-spinner fa-spin"></span>
                                    Send to bill
                                </ui-dropdown-item>
                            </ui-dropdown>
                        </ui-button-group>
                    </div>
                    <div v-else>
                        <ui-button variant="outline-primary" icon="fas fa-download"
                            :disabled="data.item.status === 'pending'" :loading="downloadingInvoice && selectedID === data.item.id"
                            @click="downloadInvoice(data.item.id)">
                            Download
                        </ui-button>
                    </div>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiButtonGroup from '@/components/ui/buttons/ButtonGroup';
import UiDropdown from '@/components/ui/dropdown/Dropdown';
import UiDropdownItem from '@/components/ui/dropdown/DropdownItem';
import table_mixin from '@/mixins/tables.mixin';
import UiBadge from '@/components/ui/Badge';

export default {
    components: {
        UiButton,
        UiButtonGroup,
        UiDropdown,
        UiDropdownItem,
        DateRangePicker,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiStatus,
        UiTable,
        UiTableSkeleton,
        UiBadge
    },
    mixins: [table_mixin],

    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            downloadingInvoice: false,
            selectedID: 0,
            busy: false,
            invoices: null,
            creating: false,
            fields: [
                { key: 'name', label: 'Name' },
                { key: 'amount', label: 'Amount' },
                { key: 'concepts', label: 'Concepts' },
                { key: 'month', label: 'Month' },
                { key: 'year', label: 'Year' },
                { key: 'status', label: 'Status' },
                { key: 'created_at', label: 'Date' },
                { key: 'updated_at', label: 'Updated At' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
                status: null,
                month: null,
                year: null,
            },
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
            sendingToBill: false,
        };
    },

    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getInvoices);
            },
        },
        $route(to) {
            if (to.name === 'invoices') {
                this.filters = this._filtersByQuery(this.filters);
            }
        },
    },

    async mounted() {
        await this._getFilters('invoices');
    },

    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        async downloadInvoice(id) {
            try {
                this.selectedID = id;
                this.downloadingInvoice = true;
                const options = { responseType: 'blob' };
                const response = await this.$axios.get(`/_/invoices/cfdi/download/${id}`, options);
                const file = window.URL.createObjectURL(new Blob([response.data]));
                const file_url = document.createElement('a');

                file_url.href = file;
                file_url.setAttribute('download', `invoice-${id}.zip`);
                document.body.appendChild(file_url);
                file_url.click();
                document.body.removeChild(file_url);
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.downloadingInvoice = false;
                this.selectedID = 0;
            }
        },
        async sendToBill(id) {
            try {
                this.sendingToBill = true;
                const { data } = await this.$axios.post(`/_/invoices/${id}/bill`);

                this.$refs.dropdown.$el.click();
                this.$emit('invoiceUpdated', data);
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.sendingToBill = false;
            }
        },
        async getInvoices() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    account: formattedFilters.account,
                    status: formattedFilters.status,
                    month: formattedFilters.month,
                    year: formattedFilters.year,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/invoices', { params });
                this.total_pages = data.pages;
                this.invoices = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
    },

}
</script>

<style lang="scss">
.invoices-list {
    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        font-weight: 600;
        color: $general-black;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }

    &__contact-button {
        align-items: center;
        background-color: #eeeeee;
        border-radius: 4px;
        color: $white !important;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        line-height: 1;
        padding: 8px;
        text-decoration: none;
        transition: 300ms all;
        width: 32px;

        &--whatsapp {
            background-color: #2ecc71;
            font-size: 18px;

            &:hover {
                background-color: rgba(#2ecc71, 0.8);
            }
        }

        &--phone {
            background-color: #0283cc;

            &:hover {
                background-color: rgba(#0283cc, 0.8);
            }
        }

        &--email {
            background-color: #ffb136;

            &:hover {
                background-color: rgba(#ffb136, 0.8);
            }
        }
    }

    &__verified-badge {
        cursor: pointer;
    }

    &__stage-status {
        cursor: pointer;
    }

    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>
